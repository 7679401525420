import React from 'react';
import { Icon } from 'antd';

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page k0vptj7pcl-editor_css' },
  logo: {
    className: 'header0-logo k0vpknyyiod-editor_css',
    children: '/static/images/favicon.ico',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#Services',
          children: [
            {
              children: (
                <>
                  <p>Services</p>
                </>
              ),
              name: 'text',
              className: 'k0vpkekxw3-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#Projects',
          children: [
            {
              children: (
                <>
                  <p>Projects</p>
                </>
              ),
              name: 'text',
              className: 'k0vpkekxw3-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#Contact',
          children: [
            {
              children: (
                <>
                  <p>Contact</p>
                </>
              ),
              name: 'text',
              className: 'k0vpkjgc2u-editor_css',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title k0vpih7a9e-editor_css',
    children: (
      <>
        <div style={{ minHeight: '4vh'}}></div>
        <p>
          <b style={{ fontSize: '38px' }}>Percolatorly</b>
        </p>
      </>
    ),
  },
  content: {
    className: 'banner0-content k0vpj6u0wsu-editor_css',
    children: (
      <>
        <div style={{ minHeight: '8vh'}}></div>
        <p style={{ fontSize: '14px', lineHeight: '200%' }}>Technology Architecture and Consulting for the Digital Age</p>
        <div style={{ minHeight: '8vh'}}></div>
      </>
    ),
  },
  button: {
    className: 'banner0-button k0vpk9w9mcm-editor_css',
    children: 'Learn More',
    href: '#Services',
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Services' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              '/static/images/pic07.jpg',
          },
          title: { children: 'Architecture' },
          content: { children: 'Business scoping and technology design of complex systems. Implemented through an agile development process.' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              '/static/images/pic03.jpg',
          },
          title: { children: 'API Design' },
          content: { children: 'Leading the fuctional design of the Open Banking APIs in the UK and now in NZ. A deep understanding of architectural design patterns.' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              '/static/images/pic02.jpg',
          },
          title: { children: 'Development' },
          content: { children: 'IT development on a modern architecture stack which includes Google Cloud Platform, Kubernetes, Golang, React JS.' },
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: '',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Open Banking' },
  content: {
    className: 'content1-content k0vpwspjgj-editor_css',
    children: 
      'Authored the Open Banking APIs in the UK and in NZ. Covering functional aspects of payment initiation and account and transaction information. Designed a world leading payment consent model for long lived consent. Simple, easy to understand APIs.',
  },
};
export const Content11DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: '',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Cloud Native Web Services' },
  content: {
    className: 'content1-content k0vpwspjgj-editor_css',
    children:
      'Designed and built highly available cloud native infrastructure, with sub-millisecond response times. Developing on a stack including Google Cloud Platform, Kubernetes, Golang and React.',
  },
};
export const Content12DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: '',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Data Commercialisation' },
  content: {
    className: 'content1-content k0vpwspjgj-editor_css',
    children:
      'Designed architecture for large data commercialisation projects. Data pipelines and infrastructure for data science projects.',
  },
};
export const Content13DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: '',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: 'Data Systems' },
  content: {
    className: 'content1-content k0vpwspjgj-editor_css',
    children:
      'Designed and built greenfield data systems for organisations. From data warehouse platforms to reporting systems. Understanding business needs and how this translates to data systems.',
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'content',
        className: 'title-content',
        children: (
          <>
            <p style={{ lineHeight: '200%' }}>Our Principal Architect Gavin Wong co-authored the Open Banking API specifications for the UK and has brought thought leadership to how payments and financial services will evolve in the API economy. Gavin has a range of experience working as a Lead Architect. He has extensive experience in greenfield projects - looking at new and innovative data technologies.</p>
            <div style={{ minHeight: '4vh' }}></div>
            <span style={{ textAlign: 'center' }}>
              <img src='/static/images/bio01.jpg' width="265px" alt="img" />
            </span>
            <div style={{ minHeight: '4vh' }}></div>
            <p style={{ lineHeight: '200%' }}>Contact us to find out more about how we can help you.</p>
            <div style={{ minHeight: '2vh' }}></div>
            <a href="mailto:contact@percolatorly.com"><Icon type="mail" /> contact@percolatorly.com</a>
            <div style={{ minHeight: '18vh' }}></div>
          </>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
    ],
  },
};

export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <div style={{ minHeight: '30vh' }}></div>
        <span>
          ©2020 Percolatorly
          <br />
          <a href="mailto:contact@percolatorly.com"><Icon type="mail" /> contact@percolatorly.com</a>
        </span>
        <div style={{ minHeight: '10vh' }}></div>
      </>
    ),
  },
};
