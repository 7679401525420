import React from 'react';
import { getChildrenToRender } from './utils';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

class Content3 extends React.PureComponent {
  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            <OverPack style={{ overflow: 'hidden' }} playScale={0.2}>
              <QueueAnim key="queue"
                className="queue-simple"
                leaveReverse={true}
              >
                {dataSource.titleWrapper.children.map(getChildrenToRender)}
              </QueueAnim>
            </OverPack>
          </div>
        </div>
      </div>
    );
  }
}

export default Content3;
