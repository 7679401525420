/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav0 from './Nav0';
import Banner0 from './Banner0';
import Content0 from './Content0';
import Content1 from './Content1';
import Content3 from './Content3';
import Footer0 from './Footer0';

import {
  Nav00DataSource,
  Banner00DataSource,
  Content00DataSource,
  Content10DataSource,
  Content11DataSource,
  Content12DataSource,
  Content13DataSource,
  Content30DataSource,
  Footer00DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location } = window;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
    };
  }

  componentDidMount() {
    let pictures = [
      '/static/images/favicon.ico',
      '/static/images/bg.jpg',
      '/static/images/pic01.jpg',
      '/static/images/pic02.jpg',
      '/static/images/pic03.jpg',
      '/static/images/pic07.jpg',
      '/static/images/bio01.jpg'
    ]
    pictures.forEach((picture) => {
      let img = new Image();
      img.src = picture;
    });
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
  }

  render() {
    const children = [
      <Nav0
        id="Nav0_0"
        key="Nav0_0"
        dataSource={Nav00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner0
        id="Banner0_0"
        key="Banner0_0"
        dataSource={Banner00DataSource}
        isMobile={this.state.isMobile}
      />,
      <div key="Separator0_0" style={{ minHeight: '6vh' }}></div>,
      <Content0
        id="Services"
        key="Content0_0"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}
      />,
      <div key="Separator0_1" className="title-wrapper" id="Projects"></div>,
      <div key="Separator0_2" className="title-wrapper" style={{ textAlign: 'center' }}><h1 style={{ fontSize: '32px', color: 'rgba(0, 0, 0, 0.65)', marginBottom: '16px', marginTop: '60px' }}>Projects</h1><div style={{ minHeight: '4vh' }}></div></div>,
      <Content1
        id="Content1_0"
        key="Content1_0"
        dataSource={Content10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content1
        id="Content1_1"
        key="Content1_1"
        dataSource={Content11DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content1
        id="Content1_2"
        key="Content1_2"
        dataSource={Content12DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content1
        id="Content1_3"
        key="Content1_3"
        dataSource={Content13DataSource}
        isMobile={this.state.isMobile}
      />,
      <div key="Separator0_3" style={{ minHeight: '3vh' }}></div>,
      <div key="Contact" className="title-wrapper" id="Contact"></div>,
      <div key="Separator0_4" className="title-wrapper" style={{ textAlign: 'center' }}><h1 style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: '32px', marginTop: '60px' }}>Contact</h1></div>,
      <Content3
        id="Content3_0"
        key="Content3_0"
        dataSource={Content30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        style={{ backgroundColor: '#FDFDFD' }}
        ref={(d) => {
          this.dom = d;
        }}
      >
        {this.state.show && children}
      </div>
    );
  }
}
